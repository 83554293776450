<template>
  <ion-item :button="isButton">
    <ion-thumbnail slot="start">
      <img v-if="facility.profile_image" :src="`${$apiStorageUrl}/${facility.profile_image}`">
    </ion-thumbnail>
    <ion-label text-wrap>
      <h2>{{ facility.name }}</h2>
    </ion-label>
  </ion-item>
</template>

<script>

export default {
  props: {
    facility: {
      required: true,
      type: Object,
    },
    isButton: {
      type: Boolean,
      default: false,
    },
  },
}
</script>