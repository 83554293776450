<template>
  <ion-page>
    <ion-header class="container mx-auto">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/" color="moto" text="" />
        </ion-buttons>
        <ion-title>{{ $t('search') }}</ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          type="search"
          :animated="true"
          :debounce="500"
          ref="searchbar"
          :color="error ? 'danger' : 'secondary'"
          @ionClear="clearSearch"
          :placeholder="$t('searchglobalplaceholder')"
          @ionChange="searchChanged"
          @ionInput="inputChanged"
          @ionCancel="clearSearch"
        />
      </ion-toolbar>
      <ion-toolbar>
        <ion-segment mode="md" :scrollable="true" color="moto" :value="selectedTab">
          <ion-segment-button
            mode="md"
            color="moto"
            v-for="tab in tabs"
            :key="tab.id"
            @click="tabChanged(tab.id)"
            :value="tab.id"
            layout="icon-start"
          >
            <ion-icon v-if="tab.id != 'all'" :name="tab.icon" color="white" size="small" />
            <ion-label color="white">{{ $t(tab.label) }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="container mx-auto">
        <ion-list v-if="error">
          <ion-item>
            <ion-label text-wrap color="danger">{{ error }}</ion-label>
          </ion-item>
        </ion-list>

        <SkeletonList v-else-if="loading || loadingDelay" />
        
        <ion-list v-else-if="error">
          <ion-item>
            <ion-label color="danger">{{ error }}</ion-label>
          </ion-item>
        </ion-list>

        <template v-else>
          <ion-list v-if="!emptySearchResults">
            <ion-list-header>
              <ion-label>{{ $t('searchresults') }}</ion-label>
            </ion-list-header>
            <ion-item-divider v-if="searchResults.facilities.length > 0" color="primary">
              <ion-label>{{ $t('searchtabfacilities') }}</ion-label>
            </ion-item-divider>
          
            <FacilityItem v-for="facility in searchResults.facilities" :key="facility.id" :facility="facility" :isButton="true" @click.native="navigateToFacility(facility.id)" />

            <ion-item-divider v-if="searchResults.events.length > 0" color="primary">
              <ion-label>{{ $t('searchtabevents') }}</ion-label>
            </ion-item-divider> 

            <EventItem  
              v-for="event in searchResults.events"
              :key="event.id"
              :event="event" 
              :showFacility="true" 
              :isButton="true"
              @click.native="navigateToEvent(event.id)"
            />

            <ion-item-divider v-if="searchResults.news.length > 0" color="primary">
              <ion-label>{{ $t('searchtabnews') }}</ion-label>
            </ion-item-divider>
            <ion-item v-for="news in searchResults.news" :key="news.id" button @click="navigateToNews(news.id)">
              <ion-thumbnail slot="start">
                <img :src="`${$apiStorageUrl}/${news.image}`" alt="News Image">
              </ion-thumbnail>
              <ion-label text-wrap>
                <ion-text color="moto"><p>{{ news.created_at|formatDateTime }}</p></ion-text>
                <h2>{{ news.title }}</h2>

                <ion-badge :color="news.type == 'NEWS' ? 'moto' : 'warning'" class="mt-4">{{ news.type }}</ion-badge>
              </ion-label>
            </ion-item>
          </ion-list>

          <ion-list v-else-if="!searchQuery || searchQuery.length <= 3">
            <ion-list-header>
              <ion-label>{{ $t('searchrecentsearches') }}</ion-label>
            </ion-list-header>
            <template v-if="userSearchHistory && userSearchHistory.length && userSearchHistory.length > 0">
              <div v-for="history in userSearchHistory" :key="history.id">
                <EventItem v-if="history.searchable_type === 'App\\Event'" :event="history.searchable" :showFacility="true" :isButton="true" @click.native="navigateToEvent(history.searchable.id)" />
                <FacilityItem v-else-if="history.searchable_type === 'App\\Facility'" :facility="history.searchable" :isButton="true" @click.native="navigateToFacility(history.searchable.id)" />
                <ion-item v-else-if="history.searchable_type === 'App\\News'" :key="history.searchable.id" button @click="navigateToNews(history.searchable.id)">
                  <ion-thumbnail slot="start">
                    <img :src="`${$apiStorageUrl}/${history.searchable.image}`" alt="News Image">
                  </ion-thumbnail>
                  <ion-label text-wrap>
                    <ion-text color="moto"><p>{{ history.searchable.created_at|formatDateTime }}</p></ion-text>
                    <h2>{{ history.searchable.title }}</h2>

                    <ion-badge :color="history.searchable.type == 'NEWS' ? 'moto' : 'warning'" class="mt-4">{{ history.searchable.type }}</ion-badge>
                  </ion-label>
                </ion-item>
              </div>
            </template>
            <ion-item v-else>{{ $t('searchnorecentsearches') }}</ion-item>
          </ion-list>

          <ion-list v-else>
            <ion-list-header>
              <ion-label>{{ $t('searchresults') }}</ion-label>
            </ion-list-header>
            <ion-item>{{ $t('searchnoresultfound') }}</ion-item>
          </ion-list>
        </template>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { routeNames  } from '@/router/config';
import EventItem from '@/components/items/EventItem';
import FacilityItem from '@/components/items/FacilityItem';
import { RecentSearchResultType } from "@/graphql/enums";
import { mapGetters } from 'vuex';
import { CURRENT_USER_GETTER } from "@/store/store-getters";
import { FEATURE_FLAG_MAPPING } from "@/configs";

import { SEARCH_QUERY, USER_SEARCH_HISTORY_QUERY } from '@/graphql/queries';
import { CREATE_RECENT_SEARCH_RESULT } from '@/graphql/mutations';

export default {
  components: {
    EventItem,
    FacilityItem,
  },
  computed: {
    searchEnabled() {
      return this.$optimizely.isFeatureEnabled(FEATURE_FLAG_MAPPING.GLOBAL_SEARCH, this.$store.getters[CURRENT_USER_GETTER]?.id, {
        user_id: this.$store.getters[CURRENT_USER_GETTER]?.id
      });
    },
    ...mapGetters([CURRENT_USER_GETTER]),
      emptySearchResults() {
        return !this.searchResults?.facilities?.length && !this.searchResults?.events?.length && !this.searchResults?.news?.length;
      },
  },
  data() {
    return {
      types: RecentSearchResultType,
      routeNames,
      loading: false,
      loadingDelay: false,
      runningTimeout: null,
      searchQuery: '',
      error: '',
      searchResults: null,
      pattern: /^[a-zA-Z0-9 ]*$/,
      userSearchHistory: [],
      tabsVisible: false,
      tabs: [
        { id: 'all', label: 'searchtaball' },
        { id: 'facilities', label: 'searchtabfacilities', icon: 'flag' },
        { id: 'events', label: 'searchtabevents', icon: 'calendar' },
        { id: 'news', label: 'searchtabnews', icon: 'paper' },
      ],
      selectedTab: 'all',
    };
  },
  created() {
    if (!this.searchEnabled) {
      this.$router.push({ name: routeNames.HOME });
      return;
    }

    this.currentUser?.id && this.fetchUserSearchHistory();
  },
  methods: {
    navigateToEvent(eventId) {
      this.saveSearchResult(eventId, this.types.EVENT);

      this.$router.push({
        name: routeNames.EVENT,
        params: { id: eventId },
      });
    },
    navigateToNews(newsId) {
      this.saveSearchResult(newsId, this.types.NEWS);

      this.$router.push({
        name: routeNames.NEWS_SINGLE,
        params: { id: newsId },
      });
    },
    saveSearchResult(searchableId, type) {
      if (!this.currentUser?.id) {
        return;
      }

      this.$apollo.mutate({
        mutation: CREATE_RECENT_SEARCH_RESULT,
        variables: {
          searchableId,
          searchableType: type,
        },
      });
    },
    navigateToFacility(facilityId) {
      this.saveSearchResult(facilityId, this.types.FACILITY);

      this.$router.push({
        name: routeNames.FACILITY,
        params: { id: facilityId },
      });
    },
    async fetchUserSearchHistory() {
      this.loading = true;

      const { data } = await this.$apollo.query({
        query: USER_SEARCH_HISTORY_QUERY,
        fetchPolicy: 'network-only',
      });

      // Store the user search history in a data property
      this.userSearchHistory = data.getRecentSearches;
      this.loading = false;
    },
    async tabChanged(tabId) {
      // how to get selected value

      this.selectedTab = tabId;
      
      this.search();
    },
    validateInput() {
      if (!this.pattern.test(this.searchQuery)) {
        this.error = this.$t('searchinvalidinput');
        return;
      }

      this.error = '';
    },
    async inputChanged($event) {
      this.searchQuery = $event.target.value;

      this.validateInput();

      if (this.searchQuery.length > 3) {
        if (this.runningTimeout) {
          clearTimeout(this.runningTimeout);
        }
        this.loadingDelay = true;

        this.runningTimeout = setTimeout(() => {
          this.loadingDelay = false;
        }, 500);
      }

      if (!this.searchQuery) {
        this.searchResults = [];
      }
    },
    async searchChanged() {
      if (this.error) {
        return;
      }

      this.search();
    },
    async search() {
      if (!this.searchQuery || !this.selectedTab || this.searchQuery.length <= 3) {
        return;
      }

      this.loading = true;

      const { data } = await this.$apollo.query({
        query: SEARCH_QUERY,
        variables: {
          search: this.searchQuery,
          type: this.selectedTab,
        },
      });

      this.searchResults = data.globalSearch;
      this.loading = false;
    },
    clearSearch() {
      this.searchQuery = '';
      this.searchResults = [];
    },
    selectTab(tabId) {
      this.selectedTab = tabId;
      this.search();
    },
  },
};
</script>