<template>
  <ion-item :button="isButton">
    <ion-thumbnail slot="start">
      <img :src="`${$apiStorageUrl}/${event.image}`">
    </ion-thumbnail>
    <ion-label text-wrap>
      <h3>
        <ion-text color="moto">
          {{ event.held_at|formatDateTime }}
        </ion-text>
      </h3>
      <ion-text>
        <h2>{{ event.title }}</h2>
      </ion-text>

      <div v-if="event.organizer && showFacility">
        <p>{{ event.organizer.name }} <span v-if="event.host_facility">({{ $t('hostedby') }}: {{ event.host_facility.name }})</span></p>
      </div>

      <ion-badge v-if="event.cancelled_at" slot="end" color="danger">
        <ion-text class="text-sm">
          {{ $t('cancelledstatus') }}
        </ion-text>
      </ion-badge>
      <ion-badge v-if="!event.cancelled_at && eventSoldOut(event)" slot="end" color="medium">
        <ion-text class="text-xs">
          {{ $t('soldoutstatus') }}
        </ion-text>
      </ion-badge>
      <ion-badge v-if="!event.cancelled_at && !eventIsOpen(event)" slot="end" color="medium">
        <ion-text class="text-xs">
          {{ $t('salenotstarted') }}
        </ion-text>
      </ion-badge>
    </ion-label>
  </ion-item>
</template>

<script>
  import { eventSoldOut, noTicketsAvailable, eventIsOpen, noProductsAvailable } from '@/utils/events';


export default {
  props: {
    isButton: {
      type: Boolean,
      default: false,
    },
    event: {
      required: true,
      type: Object,
    },
    showFacility: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    eventIsOpen(event) {
      return eventIsOpen(event);
    },
    eventSoldOut(event) {
        // competitions can not be sold out
        if (event.event_category?.name === 'COMPETITION') {
          return false
        }

        if(eventSoldOut(event) || (noTicketsAvailable(event) && noProductsAvailable(event))) {
          return true
        }

        return false;
    },
  }
}
</script>

